import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { graphql, Link } from "gatsby";
import { MenuListing } from "../components/MenuListing";
import { IAppProps } from ".";

export const query = graphql`
query MenusDimsumMenuQuery {
  dimsumSteamedJson {
    items {
      id
      name
      description
    }
  }
  alacarteAppetisersJson {
    items {
      description
      id
      name
    }
  }
  eatallyoulikeStartersJson {
    items {
      name
      id
      description
    }
  }
  traditionalJson {
    items {
      id
      name
      description
    }
  }
  allYouCanEatFeatureImage: file(relativePath: {eq: "dishes/eat-all-you-like.jpg"}) {
    childImageSharp {
      fixed {
        src
      }
    }
  }
  dimSumFeatureImage: file(relativePath: {eq: "dimsum-feature.png"}) {
    childImageSharp {
      fixed {
        src
      }
    }
  }
metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
      original{src
      }
    }
  }
}

`;

export default class MenusPage extends React.Component<IAppProps, {}>
{
    constructor(props: IAppProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="menus">
                <SEO title="Our Food &amp; Menus" image={this.props.data.metaImage.childImageSharp.original.src} />
                <section className="hero">
                    <div className="hero-content">

                        <div className="content content-right">
                            <h1>Our Menus <br /></h1>
                            <p className="lead">
                                All ingredients are freshly prepared onsite every day by traditionally trained
                                and experienced chefs who are passionate about Chinese cuisine.
                            </p>
                            <div className="button-group">
                                <Link to="/dimsum" title="View Dim Sum" className="btn btn-solid">
                                    Dim Sum
								</Link>
                                <Link to="/a-la-carte" title="View À la Carte" className="btn btn-solid">
                                    À la Carte
								</Link>
                            </div>
                            <div className="button-group">
                                <Link to="/traditional" title="View Traditional" className="btn btn-solid">
                                    Traditional
								</Link>
                                <Link to="/eat-all-you-like" title="View Eat all you like" className="btn btn-solid">
                                    Eat all you like
								</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="featured-menu">
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Dim Sum  <span>Menu</span></h1>
                            </div>
                            <div className="menu-feature-image">
                                <img src={this.props.data.dimSumFeatureImage.childImageSharp.fixed.src} alt="Dim Sum dish" />
                            </div>
                            <MenuListing menuItems={this.props.data.dimsumSteamedJson.items} limit={10} />

                            <div className="button-group text-center">
                                <Link to="/dimsum" title="View full Dim Sum menu" className="btn btn-primary">
                                    View full Dim Sum Menu
								</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="content-fluid">
                    <section className="featured-menu featured-row">
                        <div className="content">
                            <div className="menu">
                                <div className="menu-feature">
                                    <h1>À La Carte  <span>Menu</span></h1>
                                </div>
                                <MenuListing menuItems={this.props.data.alacarteAppetisersJson.items} limit={10} />

                                <div className="button-group text-center">
                                    <Link to="/a-la-carte" title="View full À LA CARTE menu" className="btn btn-primary">
                                        View full À LA CARTE Menu
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="featured-menu">
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Menu</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeStartersJson.items} limit={10} />

                            <div className="button-group text-center">
                                <Link to="/eat-all-you-like" title="View full Eat all you like menu" className="btn btn-primary">
                                    View full Eat all your like Menu
								</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="content-fluid">
                    <section className="featured-menu featured-row">
                        <div className="content">
                            <div className="menu">
                                <div className="menu-feature">
                                    <h1>Traditional  <span>Menu</span></h1>
                                </div>
                                <MenuListing menuItems={this.props.data.traditionalJson.items} limit={10} />

                                <div className="button-group text-center">
                                    <Link to="/traditional" title="View full Traditional menu" className="btn btn-primary">
                                        View full Traditional Menu
								</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Layout >
        );
    }
}